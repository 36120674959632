@import "../variables.scss";

.svc-flex-container {
  display: flex;
}

.svc-flex-row {
  display: flex;
  flex-direction: row;
  background: $background-dim;
}

.svc-full-container {
  height: 100%;
  width: 100%;
}

.svc-flex-row.svc-full-container {
  height: calcSize(60);
  min-height: calcSize(60);
  flex-grow: 1;
}
.svc-flex-row__element--growing {
  flex: 1;
  overflow: auto;
}

.svc-flex-column {
  display: flex;
  flex-direction: column;
}

.sv-action-bar--default-size-mode .sv-action-bar-separator {
  height: calcSize(5);
}
.sv-action-bar--small-size-mode .sv-action-bar-separator {
  height: calcSize(4);
}
.svc-creator--mobile {
  .sv-action-bar-separator {
    height: calcSize(3);
  }
}
.sv-action-bar--small-size-mode .sv-action-bar-item {
  font-weight: 600;
  align-items: center;
}

.svc-top-bar {
  display: flex;
  background: $background;
  box-sizing: border-box;
  box-shadow: inset 0px -1px 0px $border;

  .svc-toolbar-wrapper {
    flex: 0 0 auto;
    display: flex;

    .sv-action-bar {
      padding: 0 calcSize(1);
      justify-content: flex-end;
    }
    
    .sv-action-bar-separator {
      display: none;
    }
    .sv-action-bar > .sv-action:not(.sv-action--hidden) ~ .sv-action .sv-action-bar-separator {
      display: inline-block;
    }

    .sv-action .sv-action-bar-item {
      margin: 0 calcSize(1);
    }
  }
}
.svc-footer-bar {
  .svc-toolbar-wrapper {
    height: calcSize(6);

    .sv-action-bar {
      justify-content: center;
      padding: 0;
      width: 100%;

      height: calc(6 * #{$base-unit});
      background: $background;
      border-top: 1px solid $border;
      box-sizing: border-box;
    }
  }
}