/* Dark mode styles */
.ql-dark-theme {
    background: #2d2d2d;
    color: #f5f5f5;
  }
  .ql-dark-theme .ql-editor {
    color: #f5f5f5;
  }
  .ql-dark-theme .ql-toolbar {
    background: #3d3d3d;
    border-color: #444;
  }
  .ql-dark-theme .ql-toolbar button {
    color: #f5f5f5;
  }
  .ql-dark-theme .ql-toolbar button.ql-active,
  .ql-dark-theme .ql-toolbar button:hover {
    color: #06c;
  }
  
  /* Custom icons for undo/redo */
  .ql-snow .ql-toolbar button.ql-undo:before {
    content: "↶";
    font-size: 18px;
  }
  .ql-snow .ql-toolbar button.ql-redo:before {
    content: "↷";
    font-size: 18px;
  }

  
  