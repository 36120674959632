.sv-ranking-item--error .sv-ranking-item__index {
  background-color: $red-light;
  box-shadow: 0px 1px 2px transparent;
  border-color: transparent;
}

.sd-element--with-frame .sv-ranking-item {
  left: calcSize(-3);
}

@container (max-width: #{$sd-panel-normal-min-width}) {
  .sv-ranking--select-to-rank-horizontal {
    flex-direction: column-reverse;

    .sv-ranking__containers-divider {
      margin: calcSize(3) 0;
      height: 1px;
      width: initial;
    }

    .sv-ranking__container--empty {
      padding-top: calcSize(1);
      padding-bottom: calcSize(1);
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .sv-ranking__container {
      max-width: initial;
    }

    .sv-ranking__container--to {
      .sv-ranking-item {
        left: initial;
      }
    }

    .sv-ranking__container--empty {
      &.sv-ranking__container--to {
        .sv-ranking-item {
          left: initial;
        }

        .sv-ranking__container-placeholder {
          padding-left: calcSize(3);
          padding-right: calcSize(3);
        }
      }

      &.sv-ranking__container--from {
        .sv-ranking__container-placeholder {
          padding-right: initial;
        }
      }
    }

  }
}

@container (max-width: #{$sd-panel-medium-min-width}) {
  .sv-ranking__container--empty {
    &.sv-ranking__container--to {
      .sv-ranking__container-placeholder {
        margin-left: calcSize(-5);
        margin-right: calcSize(-5);
      }
    }
  }
}

.sd-element--with-frame .sv-ranking--mobile {
  .sv-ranking-item__icon-container {
    margin-left: 0;
    margin-right: calcSize(1);
  }

  .sv-ranking-item {
    left: 0;
  }
}

@mixin ranking_responsive {
  .sv-ranking-item__icon-container {
    margin-left: 0;
    margin-right: 0;
  }
}

.sv-ranking-item__content.sd-ranking-item__content {
  line-height: calcLineHeight(1.5);
}

.sv-dragdrop-movedown {
  transform: translate(0, 0);
  animation: svdragdropmovedown 0.1s;
  animation-timing-function: ease-in-out;
}

@keyframes svdragdropmovedown {
  0% {
    transform: translate(0, -50px);
  }

  100% {
    transform: translate(0, 0);
  }
}

.sv-dragdrop-moveup {
  transform: translate(0, 0);
  animation: svdragdropmoveup 0.1s;
  animation-timing-function: ease-in-out;
}

@keyframes svdragdropmoveup {
  0% {
    transform: translate(0, 50px);
  }

  100% {
    transform: translate(0, 0);
  }
}